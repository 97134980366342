<ng-container *ngIf="(answer$ | async) || chatAnswer as answer">
  <ng-container *ngIf="answer.sources as sources">
    <mat-divider class="divider"></mat-divider>
    <h2>{{ 'answer.sourcesHeading' | translate }}:</h2>

    <ng-container *ngFor="let source of sources; let i = index">
      <div class="table-view">
        <h3>
          {{ i + 1 }}.
          <a *ngIf="source.meta.URL as url" href="{{ url }}" target="_blank">
            {{ removeHTTPS(url) }}
          </a>

          ({{ 'answer.score' | translate }}={{ source.score }})
        </h3>

        <i
          class="fa fa-eye"
          (click)="preview($event, i)"
          (keyup.enter)="preview($event, i)"
          title="Preview"
          *ngIf="hideAllContents"
          tabindex="0"></i>
      </div>

      <!-- Only show this paragraph if i equals hoveredIndex when hideAllContents is true -->
      <p *ngIf="!hideAllContents || (hideAllContents && i === hoveredIndex)">
        {{ source.text }}
      </p>
    </ng-container>
  </ng-container>
</ng-container>
