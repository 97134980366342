<div class="wrapper">
  <app-environment-notification></app-environment-notification>
  <app-header
    [isLogin]="!isActiveAccount"
    [activeAccountName]="activeAccountName"
    (authChange)="onAuthEvent($event)"></app-header>

  <mat-sidenav-container (backdropClick)="toggleSideNav(false)">
    <mat-sidenav
      [mode]="(isTabletLandscapeUp$ | async) ? 'side' : 'over'"
      [opened]="(isSideNavOpen$ | async)!">
      <div class="sidenav-container">
        <div class="settings-history-container scrollbar">
          <mat-tab-group>
            <mat-tab label="{{ 'settings.title' | translate }}">
              <div class="tab-content">
                <app-settings></app-settings>
                <app-expert-mode></app-expert-mode>
              </div>
            </mat-tab>
            <mat-tab
              label="History"
              *ngIf="stateService.isIntelligentSearchQA$ | async">
              <div class="tab-content">
                <app-query-history></app-query-history>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <!-- <app-release-notes></app-release-notes> -->
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="menu-container">
        <div class="button-row">
          <div
            class="menu-button-container"
            [ngClass]="{
              'active-button':
                (stateService.activeMenuSubRoute$ | async) === menuSubRoute.QA
            }">
            <button
              class="menu-button"
              routerLink="{{
                menuRootRoute.INTELLIGENT_SEARCH + '/' + menuSubRoute.QA
              }}">
              {{ 'app.regularQuestions' | translate }}
            </button>

            <div class="activity-indicator">
              <mat-icon class="arrow-up"> arrow_drop_up </mat-icon>
              <span class="line"></span>
              <mat-icon class="arrow-down"> arrow_drop_down </mat-icon>
            </div>
          </div>

          <div
            class="menu-button-container"
            [ngClass]="{
              'active-button':
                (stateService.activeMenuSubRoute$ | async) === menuSubRoute.CHAT
            }">
            <button
              class="menu-button"
              routerLink="{{
                menuRootRoute.INTELLIGENT_SEARCH + '/' + menuSubRoute.CHAT
              }}">
              {{ 'app.tarkChatBot' | translate }}
            </button>

            <div class="activity-indicator">
              <mat-icon class="arrow-up"> arrow_drop_up </mat-icon>
              <span class="line"></span>
              <mat-icon class="arrow-down"> arrow_drop_down </mat-icon>
            </div>
          </div>

          <div
            class="menu-button-container"
            [ngClass]="{
              'active-button':
                (stateService.activeMenuRootRoute$ | async) ===
                menuRootRoute.DOCUMENT_CHATTING
            }">
            <button
              class="menu-button"
              routerLink="{{ menuRootRoute.DOCUMENT_CHATTING }}">
              {{ 'app.documentChatting' | translate }}
            </button>

            <div class="activity-indicator">
              <mat-icon class="arrow-up"> arrow_drop_up </mat-icon>
              <span class="line"></span>
              <mat-icon class="arrow-down"> arrow_drop_down </mat-icon>
            </div>
          </div>

          <div
            class="menu-button-container"
            [ngClass]="{
              'active-button':
                (stateService.activeMenuSubRoute$ | async) ===
                menuSubRoute.GENERATE_PROPOSAL
            }">
            <button
              class="menu-button"
              routerLink="{{
                menuRootRoute.DOCUMENT_GENERATOR +
                  '/' +
                  menuSubRoute.GENERATE_PROPOSAL
              }}">
              {{ 'documentGenerator.proposalGenerator' | translate }}
            </button>

            <div class="activity-indicator">
              <mat-icon class="arrow-up"> arrow_drop_up </mat-icon>
              <span class="line"></span>
              <mat-icon class="arrow-down"> arrow_drop_down </mat-icon>
            </div>
          </div>

          <div
            class="menu-button-container"
            [ngClass]="{
              'active-button':
                (stateService.activeMenuRootRoute$ | async) ===
                menuRootRoute.VOICE_BOT
            }">
            <button
              class="menu-button"
              routerLink="{{ menuRootRoute.VOICE_BOT }}">
              {{ 'app.voiceBot' | translate }}
            </button>

            <div class="activity-indicator">
              <mat-icon class="arrow-up"> arrow_drop_up </mat-icon>
              <span class="line"></span>
              <mat-icon class="arrow-down"> arrow_drop_down </mat-icon>
            </div>
          </div>

          <!-- <div
            class="menu-button-container"
            [ngClass]="{
              'active-button':
                (stateService.activeMenuRootRoute$ | async) ===
                menuRootRoute.INTELLIGENT_MATCH
            }">
            <button
              class="menu-button"
              routerLink="{{ menuRootRoute.INTELLIGENT_MATCH }}">
              {{ 'app.intelligentMatch' | translate }}
            </button>

            <div class="activity-indicator">
              <mat-icon class="arrow-up"> arrow_drop_up </mat-icon>
              <span class="line"></span>
              <mat-icon class="arrow-down"> arrow_drop_down </mat-icon>
            </div>
          </div> -->

          <!-- <div
            class="menu-button-container"
            [ngClass]="{
              'active-button':
                (stateService.activeMenuRootRoute$ | async) ===
                menuRootRoute.EXPERIMENTAL_FEATURES
            }">
            <button
              class="menu-button"
              routerLink="{{ menuRootRoute.EXPERIMENTAL_FEATURES }}">
              {{ 'app.experimentalFeatures' | translate }}
            </button>

            <div class="activity-indicator">
              <mat-icon class="arrow-up"> arrow_drop_up </mat-icon>
              <span class="line"></span>
              <mat-icon class="arrow-down"> arrow_drop_down </mat-icon>
            </div>
          </div> -->
        </div>
      </div>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
