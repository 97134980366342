import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const AKTIVA_FINANCE_GROUP: Configuration = {
  title: ClientName.AKTIVA_FINANCE_GROUP,
  shortName: ClientName.AKTIVA_FINANCE_GROUP,
  logoFileName: 'activa_finance_group.png',
  searchSettings: {
    index_name: SearchIndex.AKTIVA_FINANCE_GROUP,
    answerType: AnswerType.LFGA,
    retriever_number: 6,
    temperature: 0,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'estonian',
      flagClass: 'ee',
      questions: [
        'Mis on Aktiva Finance Gruppi missioon ja mis lahendusi nad pakuvad?',
        'Mis võimalusi annab Perfi kasutamine tavakliendile?',
        'Kuidas erineb Perfi kasutamine tavalisest pangaülekandest?',
        'Kuidas tasuda igakuiseid kommunnaalarveid läbi Perfi?'
      ]
    },
    {
      language: 'english',
      flagClass: 'us',
      questions: [
        'What is the mission of Aktiva Finance Group and what solutions do they offer?',
        'What opportunities does the use of Perfi provide to a regular customer?',
        'How does using Perfi differ from a regular bank transfer?',
        'How to pay monthly utility bills through Perfi?'
      ]
    }
  ]
};
