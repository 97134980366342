import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReleaseNote } from '../model/release-notes.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AccessTokenService } from 'src/app/core/data-access/access-token.service';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNoteService {
  private accessTokenService = inject(AccessTokenService);
  private httpClient = inject(HttpClient);

  releaseNotes$ = new BehaviorSubject<ReleaseNote[] | undefined>(undefined);

  async getNotes() {
    const params = { latest: false };

    try {
      const [inferenceResponse, ingestionResponse] = await Promise.all([
        this.httpClient
          .get<{ release_notes: ReleaseNote[] }>(
            `${environment.inferenceBackendUrl}/release_notes`,
            { params }
          )
          .toPromise(),
        this.httpClient
          .get<{ release_notes: ReleaseNote[] }>(
            `${environment.ingestionBackendUrl}/release_notes`, // not necessary to be connected
            { params }
          )
          .toPromise()
      ]);
      const combinedResults = [
        ...(inferenceResponse?.release_notes || []),
        ...(ingestionResponse?.release_notes || [])
      ];

      return combinedResults.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    } catch (error) {
      console.error('Failed to retrieve release notes', error);
      throw error;
    }
  }

  async handleReleaseNotes() {
    try {
      const response = await this.getNotes();
      this.releaseNotes$.next(response);
    } catch (error) {
      console.error('Error handling release notes:', error);
    }
  }
}
