import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const TELE2: Configuration = {
  title: 'Intelligent Search',
  shortName: ClientName.TELE2,
  logoFileName: 'tele2.png',
  searchSettings: {
    index_name: SearchIndex.TELE2_INDEX,
    answerType: AnswerType.LFGA,
    retriever_number: 5,
    temperature: 0.1,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'us',
      questions: [
        'What are the available mobile service plans and their respective features and prices?',
        'How can I activate or deactivate specific features or services on my mobile plan?',
        'What is the coverage area for your mobile network?',
        'How can I check my current data usage and remaining balance?',
        'What is the process for transferring my existing mobile number to your network?'
      ]
    },
    {
      language: 'estonian',
      flagClass: 'ee',
      questions: [
        'Millised on saadaolevad mobiiliteenuste paketid ning nende funktsioonid ja hinnad?',
        'Kuidas saan oma mobiilipaketis teatud funktsioone või teenuseid aktiveerida või deaktiveerida?',
        'Mis on teie mobiilsidevõrgu leviala?',
        'Kuidas ma saan kontrollida oma praegust andmekasutust ja saldot?',
        'Kuidas toimub minu olemasoleva mobiilinumbri ülekandmine teie võrku?'
      ]
    }
  ]
};
