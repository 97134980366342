import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const TEIJIN_ARAMID: Configuration = {
  title: '',
  shortName: ClientName.TEIJIN_ARAMID,
  logoFileName: 'teijin_aramid.png',
  searchSettings: {
    index_name: SearchIndex.TEIJIN_ARAMID,
    answerType: AnswerType.LFGA,
    retriever_number: 6,
    temperature: 0,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'gb',
      questions: [
        'Which industries do we work in, and what are the unique challenges in each one?',
        'Can you give me a rundown of the 2022 and 2023 sustainability reports and point out the main differences?',
        'What materials do we use in our firefighting gear, and why did we pick them?',
        'Do we have any open internship positions?',
        'Can you tell me about our involvement in Paris-Roubaix and what the main takeaways were?',
        'Can you summarize our code of conduct and highlight the key points?'
      ]
    },
    {
      language: 'dutch',
      flagClass: 'nl',
      questions: [
        'In welke sectoren werken we, en wat zijn de unieke uitdagingen in elke sector?',
        'Kun je me een overzicht geven van de duurzaamheidsrapporten van 2022 en 2023 en de belangrijkste verschillen aangeven?',
        'Welke materialen gebruiken we in onze brandweerkleding, en waarom hebben we voor deze materialen gekozen?',
        'Hebben we openstaande stageplaatsen?',
        'Kun je me vertellen over onze betrokkenheid bij Paris-Roubaix en wat de belangrijkste inzichten waren?',
        'Kun je onze gedragscode samenvatten en de belangrijkste punten benadrukken?'
      ]
    },
    {
      language: 'german',
      flagClass: 'de',
      questions: [
        'In welchen Branchen sind wir tätig, und was sind die besonderen Herausforderungen in jeder einzelnen?',
        'Kannst du mir einen Überblick über die Nachhaltigkeitsberichte von 2022 und 2023 geben und die wichtigsten Unterschiede hervorheben?',
        'Welche Materialien verwenden wir für unsere Feuerwehrkleidung, und warum haben wir sie ausgewählt?',
        'Haben wir offene Praktikumsstellen?',
        'Kannst du mir von unserer Beteiligung an Paris-Roubaix erzählen und was die wichtigsten Erkenntnisse waren?',
        'Kannst du unseren Verhaltenskodex zusammenfassen und die wichtigsten Punkte hervorheben?'
      ]
    },
    {
      language: 'japanese',
      flagClass: 'jp',
      questions: [
        '私たちはどの産業で働いており、それぞれの産業にはどんな独自の課題がありますか？',
        '2022年と2023年のサステナビリティレポートの概要を教えてもらえますか？また、主な違いを指摘してください。',
        '私たちの消防服にはどのような素材を使用しており、なぜそれらを選んだのですか？',
        'インターンシップの空きはありますか？',
        '私たちのパリ〜ルーベへの関与について教えてください。主な学びは何でしたか？',
        '私たちの行動規範を要約し、重要なポイントを強調してもらえますか？'
      ]
    }
  ]
};
