import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const NEOM: Configuration = {
  title: ClientName.NEOM,
  shortName: ClientName.NEOM,
  logoFileName: 'neom.jpg',
  searchSettings: {
    index_name: SearchIndex.NEOM,
    answerType: AnswerType.LFGA,
    retriever_number: 6,
    temperature: 0,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'us',
      questions: [
        "I received a violation notification, what's the next steps for me?",
        'What should I do if a traffic violation was recorded but I wasn’t the one driving?',
        'What types of transportation fall under road and traffic rules?',
        'What kind of vehicles require the use of helmet?',
        'I got a notification that I received penalty points, when do the expire?',
        "I don't agree with the violation, how can I appeal it?"
      ]
    },
    {
      language: 'arabic',
      flagClass: 'ae',
      questions: [
        'وصلتني مخالفة، ما هي الخطوات التالية بالنسبة لي؟',
        'ماذا أفعل إذا تم تسجيل مخالفة مرورية ولم أكن أنا من يقودها؟',
        'ما هي أنواع وسائل النقل التي تندرج تحت قواعد الطرق والمرور؟',
        'ما هي أنواع المركبات التي تتطلب استخدام الخوذة؟',
        'وصلني إشعار بأنني حصلت على نقاط جزائية، متى تنتهي صلاحيتها؟',
        'لا أوافق على المخالفة، كيف يمكنني التظلم منها؟'
      ]
    }
  ]
};
