<h1 mat-dialog-title>{{ 'settings.expertModeEditHeading' | translate }}</h1>
<mat-dialog-content class="container">
  <mat-form-field class="w-100" appearance="outline">
    <mat-label>{{ 'settings.expertModeEditLabel' | translate }}</mat-label>
    <textarea matInput cdkTextareaAutosize [formControl]="prompt"></textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">
    {{ 'buttons.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="data.text"
    [disabled]="prompt.invalid"
    (click)="updatePrompt()">
    {{ 'settings.expertModeSaveCTA' | translate }}
  </button>
</mat-dialog-actions>
