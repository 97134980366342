import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { StateService } from '../../../core/data-access/state.service';
import { History } from '../model/history.model';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { ClearHistoryDialogComponent } from './clear-history-dialog/clear-history-dialog.component';

@Component({
  selector: 'app-query-history',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogModule
  ],
  templateUrl: './query-history.component.html',
  styleUrls: ['./query-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryHistoryComponent implements OnInit {
  stateService: StateService = inject(StateService);
  dialog: MatDialog = inject(MatDialog);

  history$ = this.stateService.history$;

  ngOnInit() {
    this.stateService.loadQAHistory();
  }

  selectAnswer(history: History) {
    this.stateService.selectAnswer(history);
  }

  clearHistory() {
    const dialogRef = this.dialog.open(ClearHistoryDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stateService.clearQAHistory();
      }
    });
  }
}
