import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioModule } from '@angular/material/radio';
import { MenuSubRoute } from 'src/app/app.routes';

@Component({
  selector: 'app-sub-menu',
  standalone: true,
  imports: [CommonModule, MatRadioModule],
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubMenuComponent {
  @Input() menuItems: { label: string; route: string }[] = [];
  @Input() activeSubRoute: MenuSubRoute | undefined | null = undefined;
  @Output() menuClick: EventEmitter<string> = new EventEmitter<string>();

  onMenuClick(route: string) {
    this.menuClick.emit(route);
  }
}
