<h1 mat-dialog-title>{{ 'history.confirmTitle' | translate }}</h1>
<mat-dialog-content>
  <p>{{ 'history.confirmMessage' | translate }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'history.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="warn"
    [mat-dialog-close]="true"
    cdkFocusInitial>
    {{ 'history.clear' | translate }}
  </button>
</mat-dialog-actions>
