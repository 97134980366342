import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, map, take } from 'rxjs';
import { FeedbackDialogComponent } from '../../../shared/ui/feedback-dialog/feedback-dialog.component';
import { LangSwitcherComponent } from '../../../shared/ui/lang-switcher/lang-switcher.component';
import { StateService } from '../../data-access/state.service';
import { AuthOperation, Configuration } from '../../model/configurations.model';
import { AccessTokenService } from '../../data-access/access-token.service';
import { MenuRootRoute } from 'src/app/app.routes';
import { RouterModule } from '@angular/router';
import { emailsWithAccess } from 'src/app/shared/util/posthog-access.guard';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    TranslateModule,
    LangSwitcherComponent,
    RouterModule,
    MatSlideToggleModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() authChange = new EventEmitter();

  accessTokenService = inject(AccessTokenService);

  title$ = this.stateService.selectedConfiguration$.pipe(
    map(({ title }) => title)
  );
  logoPath$ = this.stateService.selectedConfiguration$.pipe(
    map(({ logoFileName }) => `../assets/logos/${logoFileName}`)
  );
  configurations$ = this.stateService.configurations$;
  isSideNavOpen$ = this.stateService.isSideNavOpen$;
  isTabletLandscapeUp$ = this.stateService.isTabletLandscapeUp$;
  canAccessPosthog$ = this.accessTokenService.canAccessPostHog$;

  requestFeature = 'Request Feature';
  reportBug = 'Report a bug';

  isDarkTheme = this.stateService.isDarkMode$.value;
  color: ThemePalette = 'accent';

  AuthOperation = AuthOperation;
  menuRootRoute = MenuRootRoute;

  constructor(
    private stateService: StateService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private renderer: Renderer2
  ) {
    this.translate
      .get('feedback.requestFeature')
      .pipe(takeUntilDestroyed())
      .subscribe(translation => (this.requestFeature = translation));
    this.translate
      .get('feedback.reportBug')
      .pipe(takeUntilDestroyed())
      .subscribe(translation => (this.reportBug = translation));

    if (this.isDarkTheme) {
      this.renderer.addClass(document.body, 'dark-theme');
    }
  }

  ngOnInit() {
    this.accessTokenService.activeAccountEmail$
      .pipe(
        filter(email => email !== ''),
        take(1)
      )
      .subscribe(activeAccountEmail => {
        const canAccessPosthog = emailsWithAccess.some(
          email => email.toLowerCase() === activeAccountEmail.toLowerCase()
        );
        this.accessTokenService.canAccessPostHog$.next(canAccessPosthog);
      });
  }

  toggleTheme() {
    this.isDarkTheme = !this.isDarkTheme;
    this.stateService.isDarkMode$.next(this.isDarkTheme);
    if (this.isDarkTheme) {
      this.renderer.addClass(document.body, 'dark-theme');
    } else {
      this.renderer.removeClass(document.body, 'dark-theme');
    }
  }

  updateConfiguration(configuration: Configuration) {
    this.stateService.updateSelectedConfiguration(configuration);
  }

  openDialog(feedbackSubject: string): void {
    this.dialog.open(FeedbackDialogComponent, {
      data: {
        feedbackSubject: feedbackSubject
      },
      width: '50%'
    });
  }

  changeAuth(operation: AuthOperation) {
    this.authChange.emit({ operation });
  }

  toggleSideNav(): void {
    this.stateService.toggleSideNav();
  }

  getEmailText(email: string, isFeature: boolean) {
    const subjectText = isFeature ? 'Requesting a feature' : 'Reporting a bug';
    const subject = encodeURIComponent('[Nortal Tark Core] ' + subjectText);
    const bodyText = isFeature
      ? 'I am proposing the following feature: '
      : 'I am reporting the following bug: ';
    const body = encodeURIComponent('Hello! ' + bodyText);

    return `https://outlook.office.com/mail/deeplink/compose?to=${encodeURIComponent(
      email
    )}&subject=${subject}&body=${body}`;
  }

  sendEmail(isFeature: boolean) {
    const email = isFeature
      ? 'nortal-tark-feature@nortal.com'
      : 'nortal-tark-bug@nortal.com';
    const mailContent = this.getEmailText(email, isFeature);
    window.open(mailContent, '_blank');
  }
}
