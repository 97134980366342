export interface RequestState {
  userQuery: string;
  answerText: string;
  runtimeInMillis?: number;
  sources: Source[];
  stream: boolean;
  requestInProgress: boolean;
  sourcesAreFound: boolean;
  error_code?: number | null;
  prompt?: string;
  index: any;
}

export interface Answer {
  userQuery?: string;
  runtimeInMillis?: number;
  index: string;
  answerText?: string;
  sources: Source[];
  prompt?: string | null;
  error_code?: number | null;
}

export class Answer implements Answer {}

export interface Source {
  text: string;
  score: number;
  meta: {
    URL: string;
  };
}
