import { HttpClient } from '@angular/common/http';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Answer, RequestState } from '../../../shared/model/answer.model';
import {
  QuestionAnswerQueryBody,
  QuestionAnswerQuerySettings
} from 'src/app/shared/model/query-body.model';
import { QueryFilter } from 'src/app/shared/model/query-filter.model';
import { TranslateService } from '@ngx-translate/core';
import { AccessTokenService } from 'src/app/core/data-access/access-token.service';

@Injectable({
  providedIn: 'root'
})
export class QueryService implements OnDestroy {
  private httpClient = inject(HttpClient);
  private accessTokenService = inject(AccessTokenService);
  private translate = inject(TranslateService);
  private worker: Worker;

  answerSubject = new BehaviorSubject<Answer | undefined>(undefined);
  answer$ = this.answerSubject.asObservable();
  historySubject = new BehaviorSubject<History[]>([]);
  history$ = this.historySubject.asObservable();
  requestState = this.setRequestInitialState();
  historyUpdated$ = new BehaviorSubject<Answer>(new Answer());
  requestInProgress$ = new BehaviorSubject<boolean>(false);
  stopStreaming$ = new BehaviorSubject<boolean>(false);
  private abortController: AbortController = new AbortController();

  intelligentSearchRunTimeStart = 0;

  constructor() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./query.worker.ts', import.meta.url));

      this.worker.onmessage = ({ data }) => {
        if (data === 'finished') {
          const noRequestInProgress = !this.requestState.requestInProgress;
          const stopStreaming = this.stopStreaming$.getValue();
          if (noRequestInProgress && stopStreaming) {
            this.requestState.runtimeInMillis =
              Date.now() - this.intelligentSearchRunTimeStart;
            this.requestState.requestInProgress = false;
            this.requestInProgress$.next(false);
            this.stopStreaming$.next(false);

            const nextHistory = this.answerSubject.getValue();
            if (nextHistory) {
              this.historyUpdated$.next(nextHistory);
            }
          }
        } else {
          this.requestState.answerText += data;
        }

        this.updateAnswer();
      };
    } else {
      console.log('Web Workers are not supported in your environment.');
    }
  }

  ngOnDestroy() {
    if (this.worker) {
      this.worker.terminate();
    }
  }

  public setRequestInitialState(): RequestState {
    return {
      userQuery: '',
      sources: [],
      stream: true,
      answerText: '',
      requestInProgress: true,
      sourcesAreFound: false,
      error_code: null,
      runtimeInMillis: undefined,
      index: '',
      prompt: undefined
    };
  }

  public stopStreaming() {
    this.stopStreaming$.next(true);
    this.worker.postMessage({ stopStreaming: true });
  }

  /**
   * Handles incoming streamed data, processing it character by character.
   * @param userQuery The user's query string.
   * @param querySettings Settings to configure the query.
   * @param isArabic Determines if the language is Arabic for localized messages.
   */
  async handleStreamedData(
    userQuery: string,
    querySettings: QuestionAnswerQuerySettings,
    isArabic: boolean
    //queryFilters?: Partial<QueryFilter>
  ) {
    const requestAlreadyInProgress = this.requestInProgress$.getValue();
    this.stopStreaming$.next(requestAlreadyInProgress);
    let sourcesString = '';
    this.requestState.sourcesAreFound = false;
    try {
      this.intelligentSearchRunTimeStart = Date.now();

      this.requestState = this.setRequestInitialState();
      this.updateRequestState({
        answerText: isArabic
          ? '🔎 البحث بعمق للعثور على أفضل التطابقات لسؤالك...'
          : this.translate.instant('query.diggingDeep'),
        userQuery,
        sources: undefined,
        runtimeInMillis: undefined
      });
      this.updateAnswer();

      const response = await this.fetchData(
        userQuery,
        querySettings
        //queryFilters
      ).toPromise();

      if (response.length === 0) {
        const err = this.translate.instant('query.errorServerResponse');
        throw new Error(`${err} ${response.status}`);
      }

      const reader = new ReadableStream({
        start(controller) {
          controller.enqueue(response);
          controller.close();
        }
      }).getReader();
      const delay = 10; // Initialize to 10ms for all characters

      let dataBuffer = '';
      let tempBuffer = ''; // Temporary buffer to hold characters after '['
      let isCheckingForTag = false; // Flag to indicate if we are checking for '[NO_ANSWER_FOUND]'

      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (this.stopStreaming$.getValue()) {
          this.stopStreaming$.next(false);
          this.clearAnswerPartially();
          break;
        }

        if (reader) {
          const { value, done } = await reader.read();

          if (done) {
            this.updateAnswer();
            this.stopStreaming$.next(true);
            //this.requestInProgress$.next(false);
            //this.requestState.requestInProgress = false;
            break;
          }
          const chunk = value
            .replace(/data: {2}-/g, '    *')
            .replace(/\ndata: /g, '')
            .replace(/data: /g, '')
            .replace(/\n$/, '');

          if (!this.requestState.sourcesAreFound) {
            sourcesString += chunk;
          } else {
            dataBuffer += chunk;
          }

          if (sourcesString.includes('__DOCS_END__')) {
            this.requestState.sourcesAreFound = true;
            dataBuffer = this.getTextFromFoundFiles(sourcesString, isArabic);
            sourcesString = '';
          }

          if (this.requestState.sourcesAreFound) {
            const streamStartIndex = dataBuffer.indexOf('__STREAM_START__');
            if (streamStartIndex !== -1) {
              this.requestState.answerText = '<div></div>';
              this.updateAnswer();
              dataBuffer = dataBuffer.substring(
                streamStartIndex + '__STREAM_START__'.length
              );
            }

            dataBuffer = this.replaceNoneFoundTags(dataBuffer);

            for (const char of dataBuffer) {
              if (this.stopStreaming$.getValue()) break;

              if (isCheckingForTag) {
                tempBuffer += char;

                if (tempBuffer === 'NO_ANSWER_FOUND]') {
                  //console.log('[NO_ANSWER_FOUND] found. Removing it.');
                  tempBuffer = '';
                  isCheckingForTag = false;
                } else if (!'[NO_ANSWER_FOUND]'.startsWith(tempBuffer)) {
                  // If the temporary buffer doesn't match the beginning of the tag, append it to the answer and reset
                  await this.processChar('[' + tempBuffer, delay);
                  tempBuffer = '';
                  isCheckingForTag = false;
                }
              } else {
                // Found '['. Starting to check for '[NO_ANSWER_FOUND]
                if (char === '[') {
                  tempBuffer = '';
                  isCheckingForTag = true;
                } else if (!this.stopStreaming$.getValue()) {
                  await this.processChar(char, delay);
                }
              }
            }
            dataBuffer = '';
          }
        }
      }
    } catch (error: any) {
      this.handleError(error);
    } finally {
      this.requestState.requestInProgress = false;
    }
  }

  private fetchData(
    userQuery: string,
    querySettings: QuestionAnswerQuerySettings
    //queryFilters?: Partial<QueryFilter>
  ): Observable<any> {
    this.abortPreviousRequest();

    const url = `${environment.inferenceBackendUrl}/get_answer`;
    //queryFilters = this.checkQueryFilters(queryFilters);

    const body: QuestionAnswerQueryBody = {
      query: userQuery,
      index_name: querySettings.index_name,
      similarity_top_k: querySettings.similarity_top_k,
      session_id: this.accessTokenService.activeAccountEmailUsername$.value,
      model: querySettings.model,
      search_mode: querySettings.search_mode,
      streaming: querySettings.streaming,
      evaluate: querySettings.evaluate,
      temperature: querySettings.temperature,
      expert_mode: querySettings.expert_mode
    };

    return this.httpClient.post(url, body, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'text' as 'json'
    });
  }

  private getTextFromFoundFiles(dataBuffer: string, isArabic: boolean): string {
    const [sourcesString, remainingText] = dataBuffer.split('__DOCS_END__');
    const sources = JSON.parse(sourcesString.replace(/^data: /, ''));
    this.updateRequestState({
      sources: sources,
      sourcesAreFound: true,
      answerText: isArabic
        ? '📚 وجدنا بعض الوثائق المتميزة التي تتناسب بشكل وثيق مع سؤالك! إليك قائمتها أدناه. فقط لتعلم، لقد استخدمنا هذه الوثائق تحديداً لتعزيز بيانات الذكاء الاصطناعي لدينا، مما يضمن لك الحصول على إجابة مصممة خصيصاً لك. تابع معنا! 🕐'
        : this.translate.instant('query.foundFiles')
    });
    this.updateAnswer();
    return remainingText;
  }

  //const noAnswer = dataBuffer.indexOf('[NO_ANSWER_FOUND]');
  //if (noAnswer !== -1) {
  private replaceNoneFoundTags(dataBuffer: string) {
    if (
      dataBuffer.includes('[NO_ANSWER_FOUND]') ||
      dataBuffer.includes('_ANSWER_FOUND]') ||
      dataBuffer.includes('[NO')
    ) {
      dataBuffer = dataBuffer.replace('[NO_ANSWER_FOUND]', '');
      dataBuffer = dataBuffer.replace('[NO', '');
      dataBuffer = dataBuffer.replace('_ANSWER_FOUND]', '');
      this.updateAnswer();
    }
    return dataBuffer;
  }

  private processChar = async (char: string, delay: number) => {
    this.requestInProgress$.next(true);
    this.requestState.requestInProgress = true;
    return new Promise<void>((resolve, reject) => {
      try {
        if (!this.stopStreaming$.getValue()) {
          this.worker.postMessage({
            char,
            delay,
            stopStreaming: this.stopStreaming$.getValue()
          });
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  private updateRequestState(changes: Partial<RequestState>): void {
    this.requestState = { ...this.requestState, ...changes };
  }

  private updateAnswer() {
    const answer: Answer = {
      answerText: this.requestState.answerText,
      sources: this.requestState.sources,
      runtimeInMillis: this.requestState.runtimeInMillis,
      index: this.requestState.index,
      error_code: this.requestState.error_code,
      prompt: this.requestState.prompt,
      userQuery: this.requestState.userQuery
    };
    this.answerSubject.next({ ...answer });
  }

  private clearAnswerPartially() {
    this.answerSubject.next({
      answerText: this.requestState.answerText,
      runtimeInMillis: undefined,
      index: this.requestState.index,
      error_code: this.requestState.error_code,
      prompt: this.requestState.prompt,
      userQuery: this.requestState.userQuery,
      sources: []
    });
  }

  private handleError(error: any) {
    console.error(`Error in handleStreamedData(): ${error.message}`);
    const errMessage = this.translate.instant('query.errorFetchingData');
    if (
      error.message === 'The user aborted a request.' ||
      error.message === 'BodyStreamBuffer was aborted.'
    ) {
      this.updateRequestState({
        error_code: error.code ?? 400,
        answerText: `${error.message}`
      });
    } else {
      this.updateRequestState({
        error_code: error.code ?? 400,
        answerText: `${errMessage}: ${error.message}`
      });
    }
    this.updateAnswer();
    this.stopStreaming$.next(true);
  }

  private abortPreviousRequest() {
    const newAbortController = new AbortController();
    this.abortController.abort();
    this.abortController = newAbortController;
  }

  /* private checkQueryFilters(
    queryFilters: Partial<QueryFilter> | undefined
  ): Partial<QueryFilter> | undefined {
    if (!queryFilters) {
      return undefined;
    }

    const filters = { ...queryFilters };

    if (!filters?.sector?.length) {
      delete filters?.sector;
    }
    if (!filters?.industry_type?.length) {
      delete filters?.industry_type;
    }
    if (!filters?.service_offering?.length) {
      delete filters?.service_offering;
    }
    return filters;
  } */
}
