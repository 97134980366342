import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  inject
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { QueryFilter, QueryFilterType } from '../../model/query-filter.model';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { StateService } from 'src/app/core/data-access/state.service';
import { MatCardModule } from '@angular/material/card';
import { FileUploadService } from 'src/app/core/data-access/file-upload.service';
import { take } from 'rxjs';
import { ClientName } from '../../model/query-body.model';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/core/data-access/loader.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
  selector: 'app-query-field',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    TranslateModule
  ],
  templateUrl: './query-field.component.html',
  styleUrls: ['./query-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryFieldComponent implements OnChanges {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Input({ required: true }) isLoading = true;
  @Input({ required: true }) isShouldReset = true;
  @Input({ required: false }) isShowFiltersButton = true;
  @Input({ required: false }) isShowDataIncludedButton = true;
  @Input() example = '';
  @Input({ required: false }) featureClass: string;

  @Output() sendQuery = new EventEmitter<string>();

  loaderService = inject(LoaderService);
  public stateService = inject(StateService);
  private uploadService = inject(FileUploadService);
  private _ngZone = inject(NgZone);

  query = new FormControl('');
  QueryFilterType = QueryFilterType;
  filters: QueryFilter = this.stateService.getQueryFilters();
  isShowFilters = false;
  sectors$ = this.uploadService.getSectors().pipe(take(1));
  industryTypes$ = this.uploadService.getIndustryTypes().pipe(take(1));
  serviceOfferings$ = this.uploadService.getServiceOfferings().pipe(take(1));
  isDocChatCtaDisabled$ = this.stateService.isDocChatCtaDisabled$;

  ClientName = ClientName;
  environment = environment;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['example']) {
      this.query.patchValue(changes['example'].currentValue);
      this.query.enable();
    }

    if (changes['isLoading']) {
      changes['isLoading'].currentValue
        ? this.query.disable()
        : this.query.enable();
    }
  }

  triggerResize() {
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  onKeyDown($event: KeyboardEvent) {
    if ($event.key === 'Enter' && !$event.shiftKey) {
      $event.preventDefault();
      this.emitQuery();
    }

    if ($event.key === 'Enter' && $event.shiftKey) {
      return;
    }
  }

  setFilters(option: string, optionGroup: string) {
    if (optionGroup === QueryFilterType.SECTOR) {
      if (this.filters?.sector?.includes(option)) {
        this.filters.sector = this.filters?.sector?.filter(
          sector => sector !== option
        );
      } else {
        this.filters?.sector?.push(option);
      }
    } else if (optionGroup === QueryFilterType.INDUSTRY_TYPE) {
      if (this.filters?.industry_type?.includes(option)) {
        this.filters.industry_type = this.filters?.industry_type?.filter(
          industry_type => industry_type !== option
        );
      } else {
        this.filters?.industry_type?.push(option);
      }
    } else if (optionGroup === QueryFilterType.SERVICE_OFFERING) {
      if (this.filters?.service_offering?.includes(option)) {
        this.filters.service_offering = this.filters?.service_offering?.filter(
          service_offering => service_offering !== option
        );
      } else {
        this.filters?.service_offering?.push(option);
      }
    }
    this.stateService.setQueryFilters(this.filters);
  }

  emitQuery() {
    if (this.query.value) {
      this.sendQuery.emit(this.query.value);
      if (this.isShouldReset) {
        this.query.reset();
      }
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  showDataInfo() {
    alert('Data types');
  }
}
