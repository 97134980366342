import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { catchError, switchMap } from 'rxjs/operators';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private msalService: MsalService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const accounts = this.msalService.instance.getAllAccounts();

    if (accounts.length > 0) {
      const account =
        this.msalService.instance.getActiveAccount() || accounts[0];

      return from(
        this.msalService.acquireTokenSilent({
          account: account,
          scopes: [...environment.apiConfig.scopes]
        })
      ).pipe(
        switchMap(tokenResponse => {
          const clonedReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${tokenResponse.accessToken}`
            }
          });
          return next.handle(clonedReq);
        }),
        catchError(error => {
          if (error instanceof InteractionRequiredAuthError) {
            this.msalService.loginRedirect({
              scopes: [...environment.apiConfig.scopes]
            });
          }
          return throwError(() => error);
        })
      );
    }

    return next.handle(req);
  }
}
