import { ClientName } from 'src/app/shared/model/query-body.model';
import {
  AnswerType,
  Configuration,
  SearchIndex
} from '../configurations.model';

export const COMPASS_GROUP: Configuration = {
  title: ClientName.COMPASS_GROUP,
  shortName: ClientName.COMPASS_GROUP,
  logoFileName: 'compass_group.svg',
  searchSettings: {
    index_name: SearchIndex.COMPASS_GROUP,
    answerType: AnswerType.LFGA,
    retriever_number: 6,
    temperature: 0,
    document_chat_index: SearchIndex.DOCUMENT_CHAT_INDEX
  },
  examples: [
    {
      language: 'english',
      flagClass: 'us',
      questions: [
        'How does Compass Group contribute to greener footprint?',
        'Which sectors and where does Compass Group operate in?',
        'What are the benefits of people investing in Compass Group?',
        'What does Compass Group do for charity?',
        'What is the concept of Food&Co?',
        'What are Compass group restaurants in Helsinki?'
      ]
    },
    {
      language: 'finnish',
      flagClass: 'fi',
      questions: [
        'Miten Compass Group edistää vihreämpää jalanjälkeä?',
        'Millä aloilla ja missä Compass Group toimii?',
        'Mitkä ovat Compass Groupiin sijoittamisen edut?',
        'Mitä Compass Group tekee hyväntekeväisyyden hyväksi?',
        'Mikä on Food&Cokonsepti?',
        'Mitkä ovat Compass Groupin ravintolat Helsingissä?'
      ]
    },
    {
      language: 'german',
      flagClass: 'de',
      questions: [
        'Wie trägt die Compass Group zu einem grüneren Fußabdruck bei?',
        'In welchen Sektoren und wo ist die Compass Group tätig?',
        'Welche Vorteile haben Menschen, die in die Compass Group investieren?',
        'Was tut die Compass Group für wohltätige Zwecke?',
        'Was ist das Konzept von Food&Co?',
        'Was sind die Restaurants der Compass Group in Helsinki?'
      ]
    }
  ]
};
