import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  inject
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateService } from 'src/app/core/data-access/state.service';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatCheckboxChange,
  MatCheckboxModule
} from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { SliderComponent } from 'src/app/shared/ui/slider/slider.component';
import { ExpertModeService } from '../../data-access/expert-mode.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialog } from '@angular/material/dialog';
import { PromptDialogComponent } from '../../ui/prompt-dialog/prompt-dialog.component';

@Component({
  selector: 'app-expert-mode',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatIconModule,
    MatDividerModule,
    SliderComponent,
    MatFormFieldModule,
    MatInputModule
  ],
  templateUrl: './expert-mode.component.html',
  styleUrls: ['./expert-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpertModeComponent implements OnInit, OnDestroy {
  stateService: StateService = inject(StateService);
  expertModeService: ExpertModeService = inject(ExpertModeService);

  private dialog = inject(MatDialog);
  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.stateService.isExpertMode$
      .pipe(
        filter(isExpertMode => isExpertMode),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (!this.expertModeService.userPromptText$.value) {
          const isQAPrompt =
            this.stateService.isIntelligentSearchQA$.getValue();
          this.expertModeService.setDefaultPrompt(isQAPrompt);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateIsExpertMode($event: MatCheckboxChange): void {
    this.stateService.updateIsExpertMode($event.checked);
  }

  openDialog(event: Event, currentText: string): void {
    event.preventDefault(); // Prevent the default behavior of the textarea
    this.dialog.open(PromptDialogComponent, {
      width: '50%',
      data: { text: currentText }
    });
  }
}
