import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatMessage } from '../../../features/chatbot/model/chat-message.model';
import { SourcesComponent } from '../answer/sources/sources.component';
import { StateService } from 'src/app/core/data-access/state.service';
import { MarkdownModule } from 'ngx-markdown';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ClientName } from '../../model/query-body.model';
import { AccessTokenService } from 'src/app/core/data-access/access-token.service';
import { MatIconModule } from '@angular/material/icon';
import { map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-chat-message',
  standalone: true,
  imports: [
    CommonModule,
    SourcesComponent,
    MarkdownModule,
    MatIconModule,
    TranslateModule
  ],
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent {
  @Input() chatMessage!: ChatMessage;
  @Input({ required: true }) isLoading = true;
  @Output() scrollPositionY = new EventEmitter<number>();

  public accessTokenService = inject(AccessTokenService);
  public stateService = inject(StateService);
  private sanitizer = inject(DomSanitizer);

  messageSenderName$ = this.stateService.selectedConfiguration$.pipe(
    map(({ shortName }) => shortName)
  );

  isShowSources = false;
  ClientName = ClientName;

  get sanitizedAnswer(): SafeHtml {
    return this.chatMessage.botMessage.bot &&
      this.chatMessage.botMessage.bot.answerText
      ? this.sanitizer.bypassSecurityTrustHtml(
          this.chatMessage.botMessage.bot.answerText
        )
      : '';
  }

  toggleSources(event: any) {
    const buttonPosition = event.srcElement.offsetTop;
    this.scrollPositionY.emit(buttonPosition);
    this.isShowSources = !this.isShowSources;
  }
}
