import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-clear-history-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, TranslateModule, MatButtonModule],
  templateUrl: './clear-history-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearHistoryDialogComponent {}
