import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export enum MenuRootRoute {
  INTELLIGENT_SEARCH = 'intelligent-search',
  DOCUMENT_CHATTING = 'document-chatting',
  DOCUMENT_SEARCH = 'document-search',
  DOCUMENT_GENERATOR = 'document-generator',
  INTELLIGENT_MATCH = 'intelligent-match',
  EXPERIMENTAL_FEATURES = 'experimental-features',
  VOICE_BOT = 'voice-bot'
}

export enum MenuSubRoute {
  QA = 'qa',
  CHAT = 'chat',
  GENERATE_PROPOSAL = 'proposal',
  GENERATE_DOCUMENT = 'document',
  GENERATE_CONTACT = 'contact'
}

export const ROUTES: Route[] = [
  {
    path: MenuRootRoute.INTELLIGENT_SEARCH,
    children: [
      {
        path: MenuSubRoute.QA,
        loadComponent: () =>
          import(
            './features/intelligent-search/feature/intelligent-search-feature.component'
          ).then(mod => mod.IntelligentSearchFeatureComponent),
        canActivate: [MsalGuard]
      },
      {
        path: MenuSubRoute.CHAT,
        loadComponent: () =>
          import(
            './features/intelligent-search/feature/intelligent-search-feature.component'
          ).then(mod => mod.IntelligentSearchFeatureComponent),
        canActivate: [MsalGuard]
      },
      {
        path: '**',
        redirectTo: MenuSubRoute.QA
      }
    ]
  },
  {
    path: MenuRootRoute.DOCUMENT_CHATTING,
    loadComponent: () =>
      import(
        './features/document-chatting/feature/document-chatting-feature.component'
      ).then(mod => mod.DocumentChattingFeatureComponent),
    canActivate: [MsalGuard]
  },
  {
    path: MenuRootRoute.DOCUMENT_GENERATOR,
    children: [
      {
        path: MenuSubRoute.GENERATE_PROPOSAL,
        loadComponent: () =>
          import(
            './features/document-generator/feature/document-generator-feature.component'
          ).then(mod => mod.DocumentGeneratorFeatureComponent),
        canActivate: [MsalGuard]
      },
      /* {
        path: MenuSubRoute.GENERATE_DOCUMENT,
        loadComponent: () =>
          import(
            './features/document-generator/feature/document-generator-feature.component'
          ).then(mod => mod.DocumentGeneratorFeatureComponent),
        canActivate: [MsalGuard]
      },
      {
        path: MenuSubRoute.GENERATE_CONTACT,
        loadComponent: () =>
          import(
            './features/document-generator/feature/document-generator-feature.component'
          ).then(mod => mod.DocumentGeneratorFeatureComponent),
        canActivate: [MsalGuard]
      }, */
      {
        path: '**',
        redirectTo: MenuSubRoute.GENERATE_PROPOSAL
      }
    ]
  },
  {
    path: MenuRootRoute.VOICE_BOT,
    loadComponent: () =>
      import(
        './features/voice-bot/feature/voice-bot-feature/voice-bot-feature.component'
      ).then(mod => mod.VoiceBotFeatureComponent),
    canActivate: [MsalGuard]
  },
  /* {
    path: MenuRootRoute.INTELLIGENT_MATCH,
    loadComponent: () =>
      import(
        './features/intelligent-match/feature/intelligent-match-feature.component'
      ).then(mod => mod.IntelligentMatchFeatureComponent),
    canActivate: [MsalGuard]
  }, */
  /* {
    path: MenuRootRoute.EXPERIMENTAL_FEATURES,
    loadComponent: () =>
      import(
        './features/experimental-features/feature/experimental-features-feature.component'
      ).then(mod => mod.ExperimentalFeaturesFeatureComponent),
    canActivate: [MsalGuard]
  }, */
  {
    path: '**',
    redirectTo: MenuRootRoute.INTELLIGENT_SEARCH
  }
];
