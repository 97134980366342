<mat-chip-set
  *ngIf="questions$ | async as questions"
  class="chatbot-example-chips"
  aria-label="Chatbot example questions">
  <mat-chip
    *ngFor="let question of questions"
    (click)="onSelectExample(question)"
    >{{ question }}</mat-chip
  >
</mat-chip-set>
