<mat-toolbar>
  <div [matMenuTriggerFor]="configurationsMenu" class="logo-title-container">
    <img [src]="logoPath$ | async" alt="Logo" />
    <span>{{ title$ | async }}</span>
  </div>

  <mat-menu #configurationsMenu="matMenu" class="mat-menu">
    <button
      *ngFor="let configuration of configurations$ | async"
      mat-menu-item
      (click)="updateConfiguration(configuration)">
      {{ configuration.shortName }}
    </button>
  </mat-menu>

  <button
    class="sidenav-open-button"
    mat-icon-button
    color="primary"
    (click)="toggleSideNav()">
    <mat-icon *ngIf="(isSideNavOpen$ | async) === false"
      >chevron_right</mat-icon
    >
    <mat-icon *ngIf="isSideNavOpen$ | async">chevron_left</mat-icon>
  </button>

  <span class="spacer"></span>

  <div *ngIf="isTabletLandscapeUp$ | async" class="button-row">
    <button mat-flat-button (click)="sendEmail(true)">
      <mat-icon>comment</mat-icon>
      {{ 'feedback.requestFeature' | translate }}
    </button>
    <button mat-flat-button (click)="sendEmail(false)">
      <mat-icon>bug_report</mat-icon>
      {{ 'feedback.reportBug' | translate }}
    </button>
    <mat-slide-toggle
      [color]="color"
      [checked]="isDarkTheme"
      (change)="toggleTheme()">
      {{ isDarkTheme ? 'Dark' : 'Light' }} Mode
    </mat-slide-toggle>
  </div>

  <app-lang-switcher></app-lang-switcher>

  <button class="user-menu" [matMenuTriggerFor]="userMenu">
    <img
      *ngIf="
        accessTokenService.activeAccountPhoto$ | async as profilePhoto;
        else showIcon
      "
      class="profile-picture"
      [src]="profilePhoto"
      alt="Profile Picture" />
    <ng-template #showIcon>
      <div class="initial-icon">
        <mat-icon>person</mat-icon>
      </div>
    </ng-template>

    <div class="username-container">
      <ng-container
        *ngIf="
          accessTokenService.activeAccountName$ | async as name;
          else loginPrompt
        ">
        {{ name }}
      </ng-container>
      <ng-template #loginPrompt>
        {{ 'header.clickToLogin' | translate }}
      </ng-template>
    </div>
  </button>

  <mat-menu #userMenu="matMenu" xPosition="before">
    <ng-container *ngIf="accessTokenService.activeAccountName$ | async as name">
      <button
        mat-menu-item
        (click)="changeAuth(AuthOperation.LOGIN)"
        *ngIf="!name">
        {{ 'header.logIn' | translate }}
      </button>
      <button
        mat-menu-item
        *ngIf="name"
        (click)="changeAuth(AuthOperation.LOGOUT)">
        {{ 'header.logOut' | translate }}
      </button>
    </ng-container>
    <button
      mat-menu-item
      *ngIf="canAccessPosthog$ | async"
      routerLink="{{ menuRootRoute.POSTHOG }}">
      {{ 'header.postHog' | translate }}
    </button>
  </mat-menu>
</mat-toolbar>
