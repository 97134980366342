<div *ngIf="stateService.isShowExpertMode$ | async">
  <mat-divider></mat-divider>
  <mat-checkbox
    class="is-expert-mode"
    (change)="updateIsExpertMode($event)"
    [checked]="stateService.isExpertMode$ | async">
    <span class="checkbox-label">
      <p class="settings-heading">
        {{ 'settings.useExpertMode' | translate }}
      </p>
      <mat-icon
        matTooltip="{{ 'settings.useExpertModeTooltip' | translate }}"
        class="tooltip-icon"
        inline>
        info
      </mat-icon>
    </span>
  </mat-checkbox>

  <div *ngIf="stateService.isExpertMode$ | async" class="edit-fields-container">
    <app-slider
      [sliderLabel]="'settings.expertModeTemperature'"
      [sliderTooltip]="'settings.expertModeTemperatureTooltip'"
      [min]="0"
      [max]="1"
      [step]="0.1"
      [isTemperature]="true"
      [defaultValue]="stateService.expertModeTemperature$ | async"></app-slider>
    <div
      class="prompt-container"
      *ngIf="
        (expertModeService.userPromptText$ | async) ??
        (expertModeService.defaultPromptText$ | async) as currentPrompt
      ">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{
          'settings.expertModePreviewLabel' | translate
        }}</mat-label>
        <textarea
          matInput
          [value]="currentPrompt"
          [rows]="4"
          (click)="openDialog($event, currentPrompt)"
          readonly></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
