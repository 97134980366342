import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { Observable, combineLatest, map, startWith } from 'rxjs';
import { ExampleQuestion } from 'src/app/core/model/configurations.model';
import { StateService } from 'src/app/core/data-access/state.service';
import {
  LangChangeEvent,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';

@Component({
  selector: 'app-chatbot-example-chips',
  standalone: true,
  imports: [CommonModule, MatChipsModule, TranslateModule],
  templateUrl: './chatbot-example-chips.component.html',
  styleUrls: ['./chatbot-example-chips.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatbotExampleChipsComponent {
  private stateService = inject(StateService);
  private translateService = inject(TranslateService);

  questions$: Observable<string[]> = combineLatest([
    this.stateService.selectedConfiguration$,
    this.translateService.onLangChange.pipe(
      startWith({ lang: this.translateService.currentLang } as LangChangeEvent)
    )
  ]).pipe(
    map(([configuration, langChangeEvent]) => {
      const currentLang = langChangeEvent.lang;
      const filteredExamples: ExampleQuestion[] = configuration.examples.filter(
        example => example.flagClass === currentLang
      );
      const firstFiveExamples = filteredExamples[0].questions.slice(0, 5);
      return firstFiveExamples;
    })
  );

  onSelectExample(question: string) {
    this.stateService.useExample(question, false);
  }
}
