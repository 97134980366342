<ng-container
  *ngIf="{
    selectedConfig: stateService.selectedConfiguration$ | async,
    sectors: sectors$ | async,
    industryTypes: industryTypes$ | async,
    services: serviceOfferings$ | async,
    isDocChatCtaDisabled: isDocChatCtaDisabled$ | async,
    isLoadingFromService: loaderService.isLoading$ | async
  } as context">
  <!-- Q&A filters: begin -->
  <!-- <ng-container
    *ngIf="context.selectedConfig?.shortName === ClientName.NORTAL_TARK">
    <div class="row">
      <button
        mat-icon-button
        (click)="isShowFilters = !isShowFilters"
        *ngIf="isShowFiltersButton">
        <mat-icon>tune</mat-icon>
      </button>
      <span *ngIf="isShowFiltersButton">{{ 'query.filters' | translate }}</span>

      <ng-container *ngIf="environment.name !== 'production'">
        <a
          mat-button
          href="javascript:void(0);"
          (click)="showDataInfo()"
          *ngIf="isShowDataIncludedButton">
          {{ 'query.dataIncluded' | translate }}
        </a>
      </ng-container>
    </div>

    <ng-container *ngIf="context.sectors">
      <ng-container *ngIf="context.industryTypes">
        <ng-container *ngIf="context.services">
          <div class="popup" *ngIf="isShowFilters">
            <div class="column">
              <mat-card>
                <mat-card-header>{{
                  'query.sector' | translate
                }}</mat-card-header>
                <mat-card-content>
                  <mat-checkbox
                    *ngFor="
                      let sector of context.sectors;
                      trackBy: trackByIndex;
                      let i = index
                    "
                    [checked]="filters.sector?.includes(sector)"
                    (change)="setFilters(sector, QueryFilterType.SECTOR)"
                    [value]="sector"
                    color="primary"
                    >{{ sector }}</mat-checkbox
                  >
                </mat-card-content>
              </mat-card>
            </div>

            <div class="column">
              <mat-card>
                <mat-card-header>{{
                  'query.industryType' | translate
                }}</mat-card-header>
                <mat-card-content>
                  <mat-checkbox
                    *ngFor="
                      let type of context.industryTypes;
                      trackBy: trackByIndex;
                      let i = index
                    "
                    [checked]="filters.industry_type?.includes(type)"
                    (change)="setFilters(type, QueryFilterType.INDUSTRY_TYPE)"
                    [value]="type"
                    color="primary"
                    >{{ type }}</mat-checkbox
                  >
                  <br />
                </mat-card-content>
              </mat-card>
            </div>

            <div class="column">
              <mat-card>
                <mat-card-header>{{
                  'query.serviceOffering' | translate
                }}</mat-card-header>
                <mat-card-content>
                  <mat-checkbox
                    *ngFor="
                      let service of context.services;
                      trackBy: trackByIndex;
                      let i = index
                    "
                    [checked]="filters.service_offering?.includes(service)"
                    (change)="
                      setFilters(service, QueryFilterType.SERVICE_OFFERING)
                    "
                    [value]="service"
                    color="primary"
                    labelPosition="after"
                    >{{ service }}</mat-checkbox
                  >
                  <br />
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container> -->
  <!-- Q&A filters: end -->

  <div [ngClass]="featureClass">
    <!-- Chat input field: begin -->
    <mat-form-field
      *ngIf="featureClass === 'chatbot-feature'; else qaInputField"
      appearance="outline"
      class="mt-2">
      <mat-label>{{ 'query.label' | translate }}</mat-label>
      <textarea
        (keydown)="onKeyDown($event)"
        cdkTextareaAutosize
        matInput
        rows="1"
        [formControl]="query"></textarea>
      <div class="button-container">
        <button
          mat-flat-button
          class="button-chatting"
          [disabled]="
            context.isLoadingFromService ||
            isLoading ||
            query.value?.trim() === '' ||
            context.isDocChatCtaDisabled
          "
          (click)="emitQuery()">
          {{ 'query.cta' | translate }}
          <img [src]="'../assets/images/icon-send.svg'" alt="Logo" />
        </button>
      </div>
    </mat-form-field>
    <!-- Chat input field: end -->

    <!-- Q&A input field: begin -->
    <ng-template #qaInputField>
      <div class="qa-input-container">
        <textarea
          matInput
          (keydown)="onKeyDown($event)"
          #autosize="cdkTextareaAutosize"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="7"
          placeholder="{{ 'query.label' | translate }}"
          [formControl]="query"></textarea>

        <div class="button-container">
          <button
            mat-flat-button
            class="button-chatting"
            color="primary"
            [disabled]="isLoading || query.value?.trim() === ''"
            (click)="emitQuery()">
            {{ 'query.cta' | translate }}
            <img [src]="'../assets/images/icon-send.svg'" alt="Logo" />
          </button>
        </div>
      </div>
    </ng-template>
    <!-- Q&A input field: end -->
  </div>
</ng-container>
