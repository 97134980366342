<h1 mat-dialog-title>{{ data.feedbackSubject }}</h1>
<mat-dialog-content>
  <form [formGroup]="form" class="form">
    <mat-form-field appearance="outline">
      <mat-label>{{ 'feedback.emailLabel' | translate }}</mat-label>
      <input matInput type="email" [formControl]="form.controls.fromEmail" />
      <mat-error>{{ getSendEmailToErrorMessage() | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'feedback.messageLabel' | translate }}</mat-label>
      <textarea
        matInput
        [formControl]="form.controls.message"
        rows="6"></textarea>
      <mat-error>{{ 'feedback.messageRequired' | translate }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'buttons.cancel' | translate }}
  </button>
  <button
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    [disabled]="form.invalid"
    (click)="sendFeedback()">
    {{ 'feedback.send' | translate }}
  </button>
</mat-dialog-actions>
