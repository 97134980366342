import { SearchIndex } from 'src/app/core/model/configurations.model';
import { QueryFilter } from './query-filter.model';

export interface QuestionAnswerQueryBody {
  query: string;
  index_name?: SearchIndex;
  similarity_top_k: number;
  session_id: string;
  model: string;
  search_mode: SearchMode;
  streaming: boolean;
  evaluate: boolean;
  temperature: number;
  expert_mode: boolean;
}

export interface QuestionAnswerQuerySettings {
  index_name: SearchIndex;
  similarity_top_k: number;
  model: string | ModelType;
  search_mode: SearchMode;
  streaming: boolean;
  evaluate: boolean;
  temperature: number;
  expert_mode: boolean;
}

export interface ChatQueryBody {
  query: string;
  index_name: string;
  similarity_top_k: number;
  session_id: string;
  model: string | ModelType;
  search_mode: string;
  streaming: boolean;
  evaluate: boolean;
  chat_history: ChatHistoryRequest[];
  fast: boolean;
  temperature: number;
  expert_mode: boolean;
  filters?: Partial<QueryFilter>;
}

export enum Role {
  USER = 'user',
  ASSISTANT = 'assistant'
}

export interface ChatHistory {
  user: string;
  bot: string | undefined;
}

export interface ChatHistoryRequest {
  content: string;
  role: Role;
}

export interface ChatQuerySettings {
  index_name: string;
  similarity_top_k: number;
  model: string | ModelType;
  search_mode: string;
  streaming: boolean;
  evaluate: boolean;
  fast: boolean;
  temperature: number;
  expert_mode: boolean;
}

export enum ModelType {
  GPT4o_MINI = 'gpt-4o-mini',
  GPT_4o = 'gpt-4o',
  GEMINI_FLASH = 'gemini_1.5_flash',
  GEMINI_PRO = 'gemini_1.5_pro',
  LLAMA_3 = 'llama_3.1-405B-instruct',
  MISTRAL = 'mistral_large',
  COMMAND_R = 'command_r_plus',
  CLAUDE = 'claude_3_opus'
}

export enum SearchMode {
  HYBRID = 'hybrid',
  TEXT = 'text',
  SEMANTIC = 'semantic'
}

export enum ClientName {
  NORTAL_TARK = 'Nortal Tark',
  KSA = 'KSA Intelligent Search',
  EE = 'EE Intelligent Search',
  ESG = 'ESG Intelligent Search',
  JATHEON = 'Jatheon Intelligent Search',
  OUTUKUMPU = 'Outukumpu Intelligent Search',
  KONECRANES = 'Konecranes Intelligent Search',
  NLGA = 'NLGA Intelligent Search',
  ISBJ = 'ISBJ Intelligent Search',
  TELE2 = 'TELE2 Intelligent Search',
  SCAD = 'SCAD Intelligent Search',
  SW = 'SparkasseGPT',
  BESCHA = 'BeschA Intelligent Search',
  KSA_MISA = 'MISA Intelligent Search',
  BERLINER_VOLKSBANK = 'Volksbank Smart Helper',
  WM_NIEDERSACHSEN = 'Niedersachsen Intelligent Search',
  PIF = 'PIF Intelligent Search',
  AI_WORKSHOP = 'AI Workshop',
  AVEATO = 'Aveato Intelligent Caterer',
  JOHNSTONE_SUPPLY = 'Johnstone Supply',
  BUNDESAGENTUR_FUER_ARBEIT = 'Bundesagentur Für Arbeit',
  BLIGGIT = 'Bliggit KI',
  ENVIR = 'Keskkonnaamet',
  PWC = 'PwC',
  CALDWELL_COUNTY_INDEX = 'Caldwell County',
  BOSCH_HEALTH_INDEX = 'Bosch Health',
  COMPASS_GROUP = 'Compass Group',
  AKTIVA_FINANCE_GROUP = 'Aktiva Finance Group',
  NEOM = 'Neom',
  TEIJIN_ARAMID = 'Teijin Aramid'
}
