import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { AccessTokenService } from 'src/app/core/data-access/access-token.service';
import { environment } from 'src/environments/environment';
import {
  ExpertModePromptBody,
  PromptRequestResponse
} from '../model/expert-mode.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ExpertModeService implements OnDestroy {
  private httpClient = inject(HttpClient);
  private accessTokenService = inject(AccessTokenService);
  private translate = inject(TranslateService);
  private snackBar = inject(MatSnackBar);

  private destroy$ = new Subject<void>();

  userPromptText$ = new BehaviorSubject<string | undefined>(undefined);
  defaultPromptText$ = new BehaviorSubject<string | undefined>(undefined);

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setDefaultPrompt(isQAPrompt?: boolean) {
    const name = isQAPrompt ? 'GET_ANSWER' : 'DEFAULT_SYSTEM_PROMPT';
    this.getDefaultPrompt(name)
      .pipe(take(1))
      .subscribe(response => {
        this.defaultPromptText$.next(
          this.removeAfterSpecialChar(response.prompt)
        );

        this.setPrompt(response.prompt, true);
      });
  }

  getDefaultPrompt(name: string) {
    const url = `${environment.inferenceBackendUrl}/get_prompt_from_langfuse?name=DEFAULT_SYSTEM_PROMPT`;

    return this.httpClient.get<PromptRequestResponse>(url, {
      params: { name },
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async setPrompt(
    prompt: string,
    isDefaultPrompt?: boolean,
    isQAPrompt?: boolean
  ) {
    const name = isQAPrompt ? 'EXPERT_MODE_GET_ANSWER' : 'EXPERT_MODE';
    const response = await this.postPrompt(prompt, name).toPromise();

    if (response.status_code !== 200) {
      const errorMessage = this.translate.instant(
        'settings.expertModeGetDefaultError'
      );
      this.snackBar.open(
        errorMessage,
        this.translate.instant('buttons.close'),
        {
          panelClass: ['mat-snackbar-success'],
          duration: 3000
        }
      );
      throw new Error(`${response.message} ${response.status_code}`);
    } else {
      this.userPromptText$.next(this.removeAfterSpecialChar(prompt));

      if (!isDefaultPrompt) {
        const successMessage = this.translate.instant(
          'settings.expertModeSetPromptSuccess'
        );
        this.snackBar.open(
          successMessage,
          this.translate.instant('buttons.close'),
          {
            panelClass: ['mat-snackbar-success'],
            duration: 3000
          }
        );
      }
    }
  }

  postPrompt(prompt: string, name: string): Observable<any> {
    const url = `${environment.inferenceBackendUrl}/set_prompt`;
    const body: ExpertModePromptBody = {
      name,
      prompt,
      session_id: this.accessTokenService.activeAccountEmail$.value
    };

    return this.httpClient.post(url, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  private removeAfterSpecialChar(input: string): string {
    const specialCharIndex = input.indexOf('¦');
    if (specialCharIndex !== -1) {
      return input.substring(0, specialCharIndex);
    }
    return input;
  }
}
