import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessTokenService {
  activeAccountName$ = new BehaviorSubject<string>('');
  activeAccountEmail$ = new BehaviorSubject<string>('');
}
